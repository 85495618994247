const $ = require('jquery');
const IScroll = require('iscroll');
import 'jquery-drawer';
import 'jquery-drawer/dist/css/drawer.min.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const SmoothScroll = require('smooth-scroll');

var html = $('html');

// userAgent
var _ua = (function (u) {
  return {
    isTablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1)
      || u.indexOf("ipad") != -1
      || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
      || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
      || u.indexOf("kindle") != -1
      || u.indexOf("silk") != -1
      || u.indexOf("playbook") != -1,
    isMobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
      || u.indexOf("iphone") != -1
      || u.indexOf("ipod") != -1
      || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
      || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
      || u.indexOf("blackberry") != -1
  }
})(window.navigator.userAgent.toLowerCase());


$(function () {

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  // ドロワーメニュー
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.drawer').drawer();

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  // カルーセル
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.carousel').slick({
    slidesToShow: 5,
    speed: 3000,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplay: true,
          pauseOnHover: false,
          autoplaySpeed: 3000,
          slidesToShow: 5
        }
      }
    ]
  });

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  // スマホ以外の時に電話へのリンクをDisable
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  function phonecheck() {
    var $tel = $('a[href^="tel:"]');
    if (_ua.isMobile) {
      $tel.addClass('tel-enable');
    } else {
      $tel.addClass('tel-disable').on('click', function (e) {
        e.preventDefault();
      });
    };
  };
  phonecheck();

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  //スムーススクロールJS
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('a[href^="#"]').on('click', function () {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == "" ? 'html' : href);
    var position = target.offset().top;
    $('html, body').animate({ scrollTop: position }, 500);
    return false;
  });
});



//ーーーーーーーーーーーーーーーーーーーーーーーーーー
//retina画像の切り替え
//ーーーーーーーーーーーーーーーーーーーーーーーーーー
$(function () {
  var retinaCheck = window.devicePixelRatio;
  if (retinaCheck >= 2) { // Retinaディスプレイのときを分岐させている
    $('img.retina').each(function () {
      var retinaimg = $(this).attr('src').replace(/\.(?=(?:png|jpg|jpeg)$)/i, '@2x.');
      $(this).attr('srcset', retinaimg + " 2x");
    });
  }
});



//ーーーーーーーーーーーーーーーーーーーーーーーーーー
//ページトップJS
//ーーーーーーーーーーーーーーーーーーーーーーーーーー
$(document).ready(function ($) {
  // ブラウザウィンドウのスクロール（ピクセル単位）、その後に「戻る」リンクが表示
  "use strict";
  var offset = 300,

    //一番上のスクロールアニメーションの長さ（ミリ秒）
    scroll_top_duration = 1000,
    //「トップに戻る」リンクの指定
    $back_to_top = $('.backtotop')
  //フッターの「トップに戻る」リンクの指定

  //「トップに戻る」リンクを非表示または表示する
  $(window).scroll(function () {
    ($(this).scrollTop() > offset) ? $back_to_top.addClass('backtotop-visible') : $back_to_top.removeClass('backtotop-visible');

  });
  //スクロールでトップへ
  $back_to_top.on('click', function (event) {
    event.preventDefault();
    $('body,html').animate({
      scrollTop: 0,
    }, scroll_top_duration
    );
  });

});



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* 画像のSP PC切替
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
jQuery(function () {
  "use strict";
  // 置換の対象とするclass属性。
  var jQueryelem = jQuery('.img-switch');
  // 置換の対象とするsrc属性の末尾の文字列。
  var sp = '_sp.';
  var pc = '_pc.';
  // 画像を切り替えるウィンドウサイズ。
  var replaceWidth = 568;

  function imageSwitch() {
    // ウィンドウサイズを取得する。
    var windowWidth = parseInt(jQuery(window).width());

    // ページ内にあるすべての`.js-image-switch`に適応される。
    jQueryelem.each(function () {
      var jQuerythis = jQuery(this);
      // ウィンドウサイズが768px以上であれば_spを_pcに置換する。
      if (windowWidth >= replaceWidth) {
        jQuerythis.attr('src', jQuerythis.attr('src').replace(sp, pc));

        // ウィンドウサイズが768px未満であれば_pcを_spに置換する。
      } else {
        jQuerythis.attr('src', jQuerythis.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch();

  // 動的なリサイズは操作後0.2秒経ってから処理を実行する。
  var resizeTimer;
  jQuery(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      imageSwitch();
    }, 200);
  });
});


//ーーーーーーーーーーーーーーーーーーーーーーーーーー
//画像のロールオーバーJS
//ーーーーーーーーーーーーーーーーーーーーーーーーーー
jQuery(function () {
  "use strict";
  var preLoadImg = new Object();

  jQuery("img.rollover").each(function () {
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_ov' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    jQuery(this).hover(
      function () { this.src = onSrc; },
      function () { this.src = imgSrc; }
    );
  });

});

//ーーーーーーーーーーーーーーーーーーーーーーーーーー
// Images appears
//ーーーーーーーーーーーーーーーーーーーーーーーーーー
const myElts = document.querySelectorAll('.fadein');
const title = document.querySelectorAll(".fade-title");

let observer = new IntersectionObserver(function(entries) {
  entries.forEach(function(entry) {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add("fadein-active");
      observer.unobserve(entry.target);
    } else {
    }
  });
});

let observer2 = new IntersectionObserver(function(entries) {
  entries.forEach(function(entry) {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add("fade-title-active");
      observer.unobserve(entry.target);
    } else {
    }
  });
});

myElts.forEach(function(elt) {
  observer.observe(elt);
});

title.forEach(function(elt) {
  observer2.observe(elt)
});



/**/
$(document).ready(function(){
  $('.modal').each(function(){
          var src = $(this).find('iframe').attr('src');

      $(this).on('click', function(){

          $(this).find('iframe').attr('src', '');
          $(this).find('iframe').attr('src', src);

      });
  });
});




$(function(){
  $('.modal-close').click(function(){      
    $('iframe').attr('src', $('iframe').attr('src'));
  });
});

